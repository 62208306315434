// @flow

import { NoIndexMetaTag, PageContent, SEO } from "@components";
import { Analytics } from "@containers";
import { ContentModelMapper } from "@utils";
import { Constants } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { PAGE_TYPES } from "@utils/Constants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import { contentfulTypenames, determineStyle } from "../utils";
import { generateDetailBreadcrumb } from "../utils/Breadcrumb";
import { LEAD_SCORING_DEFAULT_VALUES } from "../utils/Constants";
import { loadLocalStorageId } from "../utils/LocalStorage";
import { getHeaderData } from "../utils/PageHeader";

const PagePersonTemplate = ({
    data: {
        page,
        channel,
        market,
        navigation,
        footer,
        site: {
            siteMetadata: { siteUrl },
        },
    },
    query,
    location,
}: *) => {
    const navData = determineStyle("Default", page.navigation || navigation);
    return (
        <LocaleContext.Provider
            value={{
                language: page.node_locale,
                market,
            }}
        >
            <StorageContext.Provider
                value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
            >
                <Analytics.PageWrapper
                    key={page.id}
                    entry={page.id}
                    type={PAGE_TYPES.PERSON}
                    title={page.title}
                    language={page.node_locale}
                    urlParameters={query}
                    slug={page.slug}
                    segments={page.segments}
                    seoKeywords={page.seoKeywords}
                    leadScoringValue={
                        page.leadScoringValue ||
                        LEAD_SCORING_DEFAULT_VALUES[PAGE_TYPES.PERSON]
                    }
                    tags={page.metadata.tags}
                >
                    <SEO
                        title={page.title}
                        translations={page.translations}
                        publishedMarkets={page.markets}
                        metaDescription={page.metaDescription}
                        siteUrl={siteUrl}
                        currentPath={location.pathname}
                        page={{ ...page, siteUrl }}
                        market={market}
                    />
                    <NoIndexMetaTag channel={channel} page={page} />
                    <PageContent
                        navigation={navData}
                        footer={page.footer || footer}
                        translations={page.translations}
                        header={
                            page.pageHeader &&
                            ContentModelMapper.map({
                                ...getHeaderData(page, market),
                                breadcrumb: generateDetailBreadcrumb(
                                    page,
                                    market,
                                ).path,
                                bntContainer:
                                    contentfulTypenames.pageTypePerson,
                            })
                        }
                        breadcrumb={
                            !page.pageHeader &&
                            generateDetailBreadcrumb(page, market)
                        }
                        contentAboveEntry={page.contentAboveEntry}
                        entry={{
                            ...page,
                            initialArticles: JSON.parse(page.initialArticles),
                            initialExperts: JSON.parse(page.initialExperts),
                        }}
                        contentBelowEntry={page.contentBelowEntry}
                        publishedMarkets={page.markets}
                        offer={page.offer}
                        slug={page.slug}
                        enableScrollTop={page.enableScrollTop}
                        hideCategoryBar
                        hideCategoryBarMobile
                        disclaimer={page.disclaimer}
                        disablePip={page.disablePip}
                    />
                </Analytics.PageWrapper>
            </StorageContext.Provider>
        </LocaleContext.Provider>
    );
};

export default withURLParameters(PagePersonTemplate);

export const query = graphql`
    query(
        $id: String!
        $language: String!
        $marketCode: String!
        $channel: String!
        $personId: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: contentfulPage(
            contentful_id: { eq: $id }
            node_locale: { eq: $language }
        ) {
            ...PageGeneral
            allExpertsSlug
            entry {
                ... on ContentfulPerson {
                    ...ExpertProfile
                }
            }
            initialArticles(
                language: $language
                personId: $personId
                marketCode: $marketCode
                collectionSize: 8
            )
            initialExperts(
                language: $language
                excludeId: $personId
                marketCode: $marketCode
                collectionSize: 4
            )
            disclaimer {
                ...Disclaimer
            }
        }
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }
        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
    }
`;
